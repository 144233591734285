import axios from 'axios';
import config from '../config/config';

const getProfile = async ( payload ) => {
  try {

    // const  data = { _id : payload._id}
    const url = `${config.backendUrl}/v1/main/users/profile/${payload._id}`;
    return await axios({
      method: 'GET',
      url,
      // data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.log("error: ",error)
    if(error.code === "ERR_BAD_REQUEST"){
      return error.response
    }
    return {
      status: 404,
    };
  }
};
export default getProfile;
