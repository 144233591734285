import axios from 'axios';
import config from '../config/config';

const getTrades = () => {
  const url = `${config.backendUrl}/v1/main/users/all-trades`;

  return axios({
    method: 'GET',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      // Check the HTTP status code for success or failure
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(`Request failed with status: ${response.status}`);
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle the error or return a default response
      return {
        status: 404,
        message: 'An error occurred while fetching data.',
      };
    });
};
export default getTrades;
