import axios from 'axios';
import config from '../config/config';

const UpdateProfile = async ( payload ) => {
  try {
    const formData = new FormData();
    formData.append('profileImage', payload.profileImage ); // Assuming you have a 'file' field in your backend to accept the file
    delete payload.profileImage; //
    const data ={userId: localStorage.getItem('userId') , ...payload}
    
    for (const key in data) {
      formData.append(key, data[key]);
    }

    const url = `${config.backendUrl}/v1/main/users/profile`;
    return await axios({
      method: 'PUT',
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.log("error: ",error)
    if(error.code === "ERR_BAD_REQUEST"){
      return error.response
    }
    return {
      status: 404,
    };
  }
};
export default UpdateProfile;
