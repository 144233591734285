import React, { useCallback, useEffect, useRef, useState } from 'react';
import { 
  // enqueueSnackbar, 
  useSnackbar } from 'notistack';
import { FormControl, FormControlLabel, Typography, Stack, Box, Button, Card, Container, Grid, IconButton, Switch, TextField } from '@mui/material';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import { Helmet } from 'react-helmet-async';
import { 
  useFormik,
  //  Field 
  } from 'formik';
import UpdateProfile from './../api/updateProfile';
import getProfile from 'src/api/getProfile';
import { useDispatch } from 'react-redux';
import { setUserProfile } from '../redux/userSession';

const UserProfile = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  console.log("loading -> userProfile: ", loading);
  const { enqueueSnackbar } = useSnackbar();
  const [publicProfile, setPublicProfile] = useState(true)
  const [selectProfileImage, setSelectedProfileImage] = useState("https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_25.jpg")
  const fileInputRef = useRef(null);

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    // Handle the selected file here
    const selectedFile = e.target.files[0];
    formik.setFieldValue('profileImage', selectedFile);
    // setSelectedProfileImage(selectedFile)

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const blobUrl = event.target.result;
        setSelectedProfileImage(blobUrl)
        // Now, you can use the blobUrl for preview
        console.log('Blob URL:', blobUrl);
      };

      reader.readAsDataURL(selectedFile);
    }
  };


  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };



  const formik = useFormik({
    initialValues: {
      profileImage: null,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      about: '',
    },
    // validationSchema: LoginSchema,

    onSubmit: async (values, { setStatus, setSubmitting }) => {

      enableLoading();
      setTimeout(() => {
        UpdateProfile(values)
          .then((res) => {
            disableLoading();


            if (res.status === 200 && res.data?.data) {

              enqueueSnackbar(res.data?.msg, {
                variant: 'success',
              });

              dispatch(setUserProfile(res?.data?.data));
              // clearFields();


            } else {
              enqueueSnackbar(`Something went Wrong!`, {
                variant: 'error',
              });
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(false);
          });
      }, 1500);
    },


  });

  const getUserProfile = useCallback(() => {
    setTimeout(() => {
      getProfile({ _id : localStorage.getItem('userId') })
      .then(  res => {

        if (res.status === 200 ) {
          const { data } = res.data;

          if(data?.profileImage){
            setSelectedProfileImage(res?.data?.data?.profileImage)
          }
          formik.setValues({ ...data });

        }

      })
      .catch(error => {

      });
    }
    ,1500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    getUserProfile()
  },[getUserProfile])

  const formFields = [
    { name: 'FirstName', value: formik.values.firstName , id: 'firstName' },
    { name: 'LastName', value: formik.values.lastName, id: 'lastName' },
    { name: 'Email', value: formik.values.email, id: 'email' },
    { name: 'Phone Number', value: formik.values.phoneNumber, id: 'phoneNumber' },
    { name: 'Address', value: formik.values.address, id: 'address' },
    { name: 'Country', value: formik.values.country, id: 'country' },
    { name: 'State/Region', value: formik.values.state, id: 'state' },
    { name: 'City', value: formik.values.city, id: 'city' },
    { name: 'Zip/Code', value: formik.values.zipCode, id: 'zipCode' },
  ];

  return (
    <>
      <Helmet>
        <title> Dashboard: Profile | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Profile
          </Typography>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction={'row'} spacing={3} className={"css-v57kt1"}>
            <Grid item xs={12} md={4} className='css-grry9j'>
              <Card elevation={0} className='css-167r2rz'>
                <Box role="presentation" tabIndex="0" className="css-m6sgpe">
                  <input accept="image/*" type="file" style={{ display: 'none' }} />
                  <Box className="css-1t5p1px">
                    <img
                      src={selectProfileImage}
                      alt="avatar"
                    />
                    <Button className="upload-placeholder css-78xr25" onClick={handleBoxClick}>
                      <IconButton
                        size="medium"
                        component="span"
                      >
                        <CameraEnhanceIcon />
                      </IconButton>
                      <Typography variant="caption">
                        Update photo
                      </Typography>
                    </Button>
                    <input
                      type="file"
                      accept="image/*"  // Optional: Specify accepted file types
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={(e) =>handleFileChange(e)}
                    />
                  </Box>


                </Box>
                <Typography
                  className="MuiTypography-root MuiTypography-caption css-1u1izvz"
                  variant="caption"
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif<br />
                  Max size of 3.1 MB
                </Typography>
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        name="isPublic"
                        value="true"
                        checked={publicProfile}
                        onChange={(e) => setPublicProfile(e.target.checked)}
                      />
                    }
                    label="Public Profile"
                    labelPlacement="start"
                  />


                </Box>
                <Button
                  variant="contained"
                  color="error"
                  size="medium"
                  style={{ marginTop: '10px' }}
                >
                  Delete User
                </Button>
              </Card>

            </Grid>
            <Grid item xs={12} md={8} className='css-1gvf2rb'>
              <Card elevation={0} className='css-137yw8i' >
                <Box className="css-122zftf">

                  {formFields.map((field) => (
                    <FormControl fullWidth key={field.id}>
                      <TextField
                        className="MuiTextField-root css-feqhe6"
                        name={field.id}
                        label={field.name}
                        variant="outlined"
                        value={formik.values[field.id]}
                        onChange={formik.handleChange}
                        error={field.id === 'email' ? Boolean(formik.errors.email) : false}
                        helperText={field.id === 'email' && formik.errors.email ? 'Invalid email' : ''}
                      />
                    </FormControl>
                  ))}

                </Box>
                <Stack spacing={2} direction="column" className='css-10u88f4'>
                  <FormControl fullWidth>
                  <TextField
                    fullWidth
                    name='about'
                    id="about"
                    label="About"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={formik.values['about']}
                    onChange={formik.handleChange}
                  />
                  </FormControl>

                  <Button
                   type="submit"
                   variant="contained"
                   size="medium"
                   color="primary"
                  >
                    Save Changes
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </form>

      </Container>

    </>


  );
};

export default UserProfile;
