import CryptoJS from 'crypto-js';
import axios from 'axios';

const dataEncryptionKey = 'mAeUgGaKaDdDaKuGEnC123';

const config = {
  url: process.env.REACT_APP_BASE_URL,
  backendUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  socketUrl: process.env.REACT_APP_SOCKET_URL,

  API_TOKEN : process.env.REACT_APP_API_TOKEN || 'API-Public-Key',
  API_SECRET: process.env.REACT_APP_API_SECRET || 'API-Private-Key',
  API_URL :process.env.REACT_APP_API_URL || 'https://api.staging.vrff.io/v1',
  WEBHOOK_PORT : process.env.WEBHOOK_PORT || 3002,
  IMAGE_DIR :process.env.IMAGE_DIR || 'documents',

  // Headers
  Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
  x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,

  uploadedImgPath: `${process.env.REACT_APP_ASSETS_BASE_URL}images/`,

  // Set user in local storage
  encryptUserData(data) {
    data = JSON.stringify(data);
    const encryptedUser = CryptoJS.AES.encrypt(data, dataEncryptionKey).toString();
    localStorage.setItem('encuse', encryptedUser);
    return true;
  },

  // generate signature
  generateSignature(payload, secret) {
    if (payload.constructor === Object) {
      payload = JSON.stringify(payload);
    }  
    
    return  CryptoJS.HmacSHA256(payload, secret).toString();
  },

  // Set active user type in local storage
  encryptActiveUserType(data) {
    data = JSON.stringify(data);
    const encryptedUser = CryptoJS.AES.encrypt(data, dataEncryptionKey).toString();
    localStorage.setItem('aut', encryptedUser);
    return true;
  },

  getRoleId() {
    const roleEncrypted = localStorage.getItem('role');
    if (roleEncrypted) {
      let role = '';
      const roleDecrypted = CryptoJS.AES.decrypt(roleEncrypted, 'secret key 123').toString(CryptoJS.enc.Utf8);
      if (roleDecrypted && roleDecrypted.trim() !== '') {
        role = JSON.parse(roleDecrypted);
      }
      return role !== '' ? role._id : null;
    }
    return null;
  },

  // Get active user type from local storage
  getActiveUserType() {
    const userTypeData = localStorage.getItem('aut');
    if (userTypeData) {
      const bytes = CryptoJS.AES.decrypt(userTypeData, dataEncryptionKey);
      const userType = parseInt(bytes.toString(CryptoJS.enc.Utf8), 10); // Add radix parameter 10
      return Number.isNaN(userType) ? 0 : userType; // Use Number.isNaN
    }
    return 0;
  },

  uploadImage(e) {
    const data = new FormData();
    data.append('image', e.target.files[0]);
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}staff/upload-image`,
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
          'x-auth-token': process.env.REACT_APP_X_AUTH_TOKEN,
        },
      })
        .then((data) => {
          resolve(data.data.data.img);
        })
        .catch((err) => {
          reject(new Error(err)); // Throw an Error object
        });
    });
  },

  // Decode passed data
  decodePassedData(data) {
    const bytes = CryptoJS.AES.decrypt(data, dataEncryptionKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  },

  // Return required keys
  getUserKeys(keys = null) {
    const userData = localStorage.getItem('encuse');
    if (userData) {
      const bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
      const originalData = bytes.toString(CryptoJS.enc.Utf8);
      const user = keys ? keys.reduce((acc, key) => ({ ...acc, [key]: originalData[key] }), {}) : originalData;
      return user;
    }
    return {};
  },

  // Clear everything from local storage
  clearStorage() {
    localStorage.removeItem('encuse');
    localStorage.removeItem('aut');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('userID');
    localStorage.removeItem('admin-accessToken');
  },

  objectToQueryString(body) {
    const qs = Object.keys(body)
      .map((key) => `${key}=${body[key]}`)
      .join('&');
    return qs;
  },

  // Strong password regex
  strongPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
  strongPasswordMsg:
    'Password must contain Upper Case, Lower Case, a Special Character, a Number and must be at least 8 characters in length.',

  // Strong password regex for student
  stdStrongPassword: /^.{8,}$/,
  stdStrongPasswordMsg: 'Password must be of at least 8 characters.',

  // CDN base URL
  cdnBaseUrl: 'https://d206oo7zkzq77l.cloudfront.net',

  isValidImageType(file) {
    if (file && file.type) {
      const acceptableTypes = ['image/png', 'image/x-png', 'image/jpeg', 'image/jpg'];
      return acceptableTypes.includes(file.type.toLowerCase());
    }
    return false; // or return undefined, null, or any other appropriate default value
  },

  integerNumberValidator(e) {
    // Allow: backspace, delete, tab, escape, enter and .
    const specialKeys = [46, 8, 9, 27, 13, 110];

    // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
    if (
      specialKeys.includes(e.keyCode) ||
      // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
      ((e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 90 || e.keyCode === 88) &&
        (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      // Let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  },

  truncTextareaLength: (val, maxlength = 150) => {
    if (val.length > maxlength) {
      val = val.substring(0, maxlength).concat('...');
    } else if ((val.match(/\n/g) || []).length) {
      const values = val.split('\n');
      if (values && values.length && values[0] !== '\n') val = values[0].concat('...');
    }
    return val;
  },
};

export default config;
