/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect , useState  } from 'react';
/* eslint-disable */
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { Box, Typography } from '@mui/material';
import { useNavigate , useLocation} from 'react-router-dom';
import io from 'socket.io-client';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
// import React, { useState, useRef, useCallback } from 'react';
import config from '../../../config/config';
// Render CardScanView


export default function Decision() {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate()

  const location = useLocation();

  useEffect(() => {
    const rootElement = document.getElementById('root');
    rootElement.className = 'backgroundImage'; // Set class based on the route (remove the leading "/")
  }, [location.pathname]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    console.log("socket.url: " + config.socketUrl);
    const socket = io(config.socketUrl); // || config.socketUrlReplace with the URL of your backend Socket.io server

    // Event handler for when the connection is established
    socket.on('connection', () => {
      console.log('Connected to Socket.io server');
    });

    // Event handler for custom events emitted by the backend
    socket.on('webhookData', (data) => {
      console.log('Received custom event:', data);
      if(data.status === "success" && data?.verification.code === 9001){
        enqueueSnackbar('You have Verified Sucessfully!', {
          variant: 'success',
        });
        navigate('/login', { replace: true });
      }
      if(data?.verification?.code === 9102 || data?.verification?.code === 9104){
        //reason  , reasonCode
        enqueueSnackbar(data?.verification?.code.reason ? data?.verification?.code?.reason :   `We can't determine the reason of fail Verification!`, {
          variant: 'error',
        });
        navigate('/verification', { replace: true });
      }
      if(data?.verification?.code === 9103 ){
        enqueueSnackbar(data?.verification?.code.reason ? data?.verification?.code?.reason :   `We can't determine the reason of fail Verification!`, {
          variant: 'error',
        });
        navigate('/verification', { replace: true });
      }


      clearInterval(timer);
      // Handle the received event data in your React component
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      clearInterval(timer);
      socket.disconnect();
    };
  }, []);
  return (
    <>
     <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Box sx={{ mb: 4, mt: 8 }}>
        <Typography variant="h4" sx={{ color: 'white' }}>
          Thank you for Submitting Information
        </Typography>
        {countdown > 0 ? (
          <Typography variant="h5" sx={{ color: 'white' }}>
            Decision will be sent to you in {countdown} minute{countdown !== 1 && 's'}
          </Typography>
        ) : (
          <Typography variant="h5" sx={{ color: 'white' }}>
            Decision has been sent to you!
          </Typography>
        )}
      </Box>
    </Box>
    </>
  );
}
