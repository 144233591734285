// @mui
import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemButton , ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease', // Add transition for smooth effect
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.selected,
    fontWeight: theme.typography.fontWeightBold,
    transform: 'scale(1.1)', // Scale up on hover
  },
}));

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s ease', // Add transition for smooth effect
});

export const StyledListItemText = styled(ListItemText)({
  transition: 'opacity 0.3s ease', // Add transition for smooth effect
});

export const NavItemInfo = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: theme.spacing(2),
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  opacity: 0, // Initially hidden
  transition: 'opacity 0.3s ease', // Add transition for smooth effect
}));

export const StyledNavItemContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  '&:hover': {
    '& > $NavItemInfo': {
      opacity: 1, // Show NavItemInfo on hover
    },
    '& > $StyledNavItemIcon': {
      transform: 'scale(1.5)', // Scale up NavItemIcon on hover
    },
    '& > $StyledListItemText': {
      opacity: 0, // Hide ListItemText on hover
    },
  },
}));