import { BrowserRouter  , useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Import your Redux store and persistor
import { store, persistor } from './redux/store';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';


// ----------------------------------------------------------------------

export default function App() {

  
  return (
    <HelmetProvider>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <ThemeProvider>
                {/* <ScrollToTop /> */}
                <StyledChart />
                <Router />
              </ThemeProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </SnackbarProvider>
    </HelmetProvider>
  );
}
