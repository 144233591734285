import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  profile: null,
  userList: null,
  userId: '',
  userById: null,
  forgotEmail: null,
  trades: null,
};
export const userSlice = createSlice({
  name: 'user', // same in the store
  initialState,
  reducers: {
    getUserData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user = action.payload;
    },

    getUserList: (state, action) => {
      state.userList = action.payload;
    },

    getUserId: (state, action) => {
      state.userId = action.payload;
    },

    getUserDetail: (state, action) => {
      state.userById = action.payload;
    },

    forgotPasswordEmail: (state, action) => {
      state.forgotEmail = action.payload;
    },

    tradesList: (state, action) => {
      state.trades = action.payload;
    },

    setUserProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { getUserData, getUserList, getUserId, getUserDetail, forgotPasswordEmail, tradesList  , setUserProfile} =
  userSlice.actions; // actions
export default userSlice.reducer;
