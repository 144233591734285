import axios from 'axios';
import config from '../config/config';

const FrontDocument = async ( context, content , sessionId) => {
  try{

    const url = `${config.url}/v1/sessions/${sessionId}/media`

    const data = {
      "image": {
        context,
        content
      }
    }
    // const requestData = ; // Convert request data to JSON string if needed
    // const secretKey = '5253a651-dfbc-4c35-aaa0-ff3a52484a0e';
    // const signature = CryptoJS.HmacSHA256(requestData, secretKey).toString();
    return await axios({
      method: 'POST',
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-CLIENT': config.API_TOKEN,
        'X-HMAC-SIGNATURE': config.generateSignature(data , config.API_SECRET )
      }
  });
  }
  catch(error){
    console.log("error: ",error)
    return {
			status: 404,
		};
  }



}

export default FrontDocument;