import { 
  // Grid, 
  Typography, Box, Card, CardHeader } from '@mui/material';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { 
  // useSelector, 
  useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import getTrades from '../../../api/tradeData';
import { tradesList } from '../../../redux/userSession';
export default function AppCardTokens({ title, subheader, chartData, ...other }) {
  const [tradeData, setTradeData] = useState([]);

  const [totalInvest, setTotalInvest] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTrades();
        // console.log('response...', response);
        if (!response) {
          throw new Error('Network response was not ok');
        }
        if (response?.status === 200) {
          console.log('inside...');
          setTradeData(response?.data);
          dispatch(tradesList(response?.data));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const calculatedTotalDeposit = tradeData.reduce((accumulator, trade) => accumulator + trade.deposit, 0);
    setTotalInvest(calculatedTotalDeposit);
  }, [tradeData]);

  return (
    <>
      <Card {...other}>
        <CardHeader title={title} />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {/* First Card */}
          <Card sx={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
            {/* Icon */}
            <Box sx={{ marginRight: '16px' }}>
              {/* Replace 'YourIconComponent1' with the actual icon component */}
              <Box
                sx={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  backgroundColor: 'blue',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <WalletIcon sx={{ fontSize: '20px', color: 'white' }} />
              </Box>
            </Box>
            {/* Content */}
            <Box>
              <Typography variant="body1">Total Investment</Typography>

              <Typography variant="h5">
                {' '}
                {totalInvest.toString()}
                {' USDT'}
              </Typography>
              {/* <Typography variant="body1">THR</Typography> */}
            </Box>
            {/* Up and Down Icons */}
            <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <ArrowUpwardIcon sx={{ color: 'green', marginRight: '8px' }} />
              {/* <Typography variant="body2" sx={{ color: 'green' }}>
                +18.01%
              </Typography> */}
            </Box>{' '}
          </Card>

          {/* Second Card */}
          <Card sx={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
            <Box sx={{ marginRight: '16px' }}>
              <Box
                sx={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  backgroundColor: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <WalletIcon sx={{ fontSize: '20px', color: 'white' }} />
              </Box>
            </Box>
            <Box>
              <Typography variant="body1">Total Trades</Typography>
              <Typography variant="h5">{tradeData?.length}</Typography>
            </Box>
            <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}></Box>
          </Card>
        </Box>
      </Card>
    </>
  );
}
