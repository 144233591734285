/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/alt-text */
import { Box, Typography, Button } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useRef, useCallback  , useEffect } from 'react';
import Webcam from 'react-webcam';
import { useNavigate  , useLocation} from 'react-router-dom';
import FrontDocument from '../../../api/frontDocument';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

// Render CardScanView
export default function BackDocument() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [picture, setPicture] = useState('');
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const webcamRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    const rootElement = document.getElementById('root');
    console.log("location.pathname.substr(1): ",location.pathname.substr(1))
    rootElement.className = "backgroundImage"; // Set class based on the route (remove the leading "/")
  }, [location.pathname]);

  const videoConstraints = {
    // width: 'inherit',
    // height: 'auto',
    facingMode,
  };

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) => (prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER));
  }, []);

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  };

  const capture = useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    const imageBlob = dataURLtoBlob(pictureSrc);
    getBase64(imageBlob, (result) => {
      setPicture(result);
    });
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleNextDocument = (e) => {
    e.preventDefault();
    enableLoading();
    setTimeout(() => {
      FrontDocument('document-back', picture, localStorage.getItem('sessionId'))
        .then((res) => {
          if (res.data.status === 'success') {
            console.log('res.data: ', res.data);
            disableLoading();
            navigate('/selfie', { replace: true });
          } else {
            console.log('api error: ');
          }
        })
        .catch((err) => {
          if (err.code === 'ENOENT') {
            console.log(err);
          } else {
            disableLoading();
          }
        });
    }, 1500);

    console.log(picture);
    setPicture('');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {loading ? (
        <>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      ) : (
        <>
          <Box
            sx={{
              mb: 4,
              mt: 8,
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: 'white',
                fontSize: {
                  xs: '24px',
                  sm: '32px',
                },
              }}
            >
              Place your Back-side of Card between the scanner
            </Typography>
          </Box>
          {/*         
          <Box sx={{ mb: 4, mt: 8 }}>
            <Typography variant="h4">Place your Back-side of Card between the scanner</Typography>
          </Box> */}

          <Box style={{maxWidth:'100%'}}> 
            {picture === '' ? (
              <Webcam
                audio={false}
                width={'100%'}
                // height={480}
                ref={webcamRef}
                screenshotFormat="image/png"
                videoConstraints={videoConstraints}
              />
            ) : (
              <img src={picture} />
            )}
          </Box>

          <Box>
            {picture !== '' ? (
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  sx={{ mt: 4, mr: 2 }}
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    setPicture('');
                  }}
                >
                  Retake
                </Button>

                <Button sx={{ mt: 4 }} variant="contained" onClick={handleNextDocument}>
                  Next
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button sx={{ mt: 4, mr: 2 }} variant="contained" onClick={handleClick}>
                  Switch Camera
                </Button>
                <Button
                  sx={{ mt: 4 }}
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    capture();
                  }}
                >
                  Capture
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
