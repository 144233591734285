import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Box } from '@mui/material';
// sections
import ProfessionalClientQuestionnaireForm from '../sections/qestionnairies/questionnairiesForm'

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontFamily: 'Comfortaa, Sans-serif',
}));

// ----------------------------------------------------------------------

export default function QuestionaPage() {
  return (
    <>
      <Box className={'addBackgroundImage'}>
        <Helmet>
          <title> Professional/non-professional client questionnaire  </title>
        </Helmet>
        <StyledRoot>
          <Container maxWidth="xl">
            <StyledContent>
              <ProfessionalClientQuestionnaireForm/>
            </StyledContent>
          </Container>
        </StyledRoot>
      </Box>
    </>
  );
}
