/* eslint-disable */

import { Box, Container } from '@mui/material';
import React from 'react';
import { SignUp } from 'src/components/auth/signUp';

function SignUpSection() {
  return (
    <>
      <Container maxWidth={false}>
        <SignUp />
      </Container>
    </>
  );
}

export default SignUpSection;
