/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet-async';
// import { faker } from '@faker-js/faker';
import { Card } from '@mui/material';

// import AppCardTokens from 'src/sections/@dashboard/app/AppCardTokens';
// import PromotionComponent from 'src/'
// @mui
import {  useDispatch, useSelector } from 'react-redux';
// import { useTheme } from '@mui/material/styles';
import { 
  Grid, 
  Container, 
  Typography, 
  // Stack, 
  // Chip,
   Box } from '@mui/material';
// components
// import Iconify from '../components/iconify';
// sections
import {
  // AppTasks,
  // AppNewsUpdate,
  // AppOrderTimeline,
  // AppCurrentVisits,
  // AppWebsiteVisits,
  // AppTrafficBySite,
  AppWidgetSummary,
  // AppCurrentSubject,
  AppConversionRates,
  PromotionComponent,
  AppCardTokens,
  // ActionCard,
} from '../sections/@dashboard/app';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import getTrades from '../../src/api/tradeData';
import { useState  , useEffect , useCallback} from 'react';
import { setUserProfile } from '../redux/userSession';
import getProfile from '../api/getProfile';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  // const theme = useTheme();
  const { profile } = useSelector((state) => state.user)
  const [tradeData, setTradeData] = useState([]);
  const [totalInvest , setTotalInvest] = useState(0)
  const trades = useSelector((state) => state.user.trades);

  async function fetchData() {
    try {
      const response = await getTrades();
   
      if (!response) {
        console.log("error fetching: " , response)
        throw new Error('Network response was not ok');
      }
      if (response?.status === 200) {
        console.log('inside...   ' ,response?.data );

        setTradeData(response?.data);
     
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const dispatch = useDispatch()

  const getUserProfile = useCallback(() => {
    setTimeout(() => {
      getProfile({ _id : localStorage.getItem('userId') })
      .then(  res => {

        if (res.status === 200 ) {
          const { data } = res.data;

          dispatch(setUserProfile(data));
        }

      })
      .catch(error => {

      });
    }
    ,1500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    getUserProfile()
  },[getUserProfile])

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const calculatedTotalDeposit = tradeData.reduce((accumulator, trade) => accumulator + trade.deposit, 0);
    // console.log("calculatedTotalDeposit: ",calculatedTotalDeposit)
    setTotalInvest(calculatedTotalDeposit);
  }, [tradeData]);


  console.log('trades...in cchart', trades);

  const ExploreButton = styled(Button)({
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    marginRight: '10px',
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#0056b3',
    },
  });

  // const handleClick = (e) => {};

  return (
    <>
      <Helmet>
        <title> Dashboard | IAESIR Finance </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, {profile?.firstName ||localStorage.getItem('firstName') || `Welcome back`}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            {/* <ActionCard /> */}
            <AppWidgetSummary
              title="Invest , Own and Be part of THR "
              body={
                <>
                  <Typography variant="paragraph">Digital MarketPlace for Crypto collection</Typography>
                </>
              }
              buttonOneLabel={'Explore'}
              buttonTwoLabel={'Add Fund'}
              color="info"
              icon={'ant-design:apple-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <PromotionComponent imageUrl={'https://enftx.vercel.app/images/bg/promo.png'} total={totalInvest}/>
            {/* <AppWidgetSummary
              title="Item Orders"
              body={<></>}
              color="warning"
              buttonOneLabel={'Place a Bid'}
              buttonTwoLabel={'Details'}
              icon={'ant-design:windows-filled'}
            /> */}
          </Grid>

          {/* <Grid item xs={12} sm={6} md={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">Tokens</Typography>
              <Stack direction="row" spacing={1}>
                <Chip label="All" variant="outlined" onClick={handleClick} />
                <Chip label="Meta-verse" variant="outlined" onClick={handleClick} />
                <Chip label="NFT" variant="outlined" onClick={handleClick} />
              </Stack>
            </Box>
          </Grid> */}

          {/* 
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}
          <Grid item xs={12} md={6} lg={3}>
            <Box mb={2}>Tokens for Investment</Box>
            <AppCardTokens title={'USDT'} />
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            {trades !== undefined && (
              <AppConversionRates
                title="AI Algorithm %"
                subheader="(+43%) than last year"
                // chartData={[
                //   // { label: 'Jan', value: 400 },
                //   // { label: 'Feb', value: 430 },
                //   // { label: 'Mar', value: 448 },
                //   // { label: 'Apr', value: 470 },
                //   // { label: 'May', value: 540 },
                //   // { label: 'Jun', value: 580 },
                //   // { label: 'Jul', value: 690 },
                //   // { label: 'Aug', value: 1100 },
                //   // { label: 'Sep', value: 1200 },
                //   // { label: 'Oct', value: 1380 },

                // ]}
                chartData={trades ? trades : ''}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '12px',
                justifyContent: 'center',
                height: '200px',
                flexDirection: 'column',
              }}
            >
              <Box pb={2}>Click on the button to withdraw</Box>
              <ExploreButton
                // onClick={handleOpen}
                variant="contained"
              >
                Withdraw
              </ExploreButton>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
