/* eslint-disable */
import { Box, Container } from '@mui/material';
import React from 'react';
// import { ForgotPass } from 'src/components/auth/forgotPass';
import { VerifyOTP } from 'src/components/auth/verifyOtp';

function VerifyOTPSection() {
  return (
    <>
      <Container maxWidth={false}>
        <VerifyOTP />
      </Container>
    </>
  );
}

export default VerifyOTPSection;
