import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';

// redux
// import { useDispatch } from 'react-redux';
// import { getUserData } from '../../../redux/userSlice';
// import { storeCookies } from '../../../utils/storeCookies';

// @mui
import {
  Radio,
  Stack,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  InputLabel,
  TextField,
  Box,
  Typography,
  RadioGroup,
} from '@mui/material';
import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import CreateSession from '../../api/createSession';
import Country from './country.enum';
// components
// import Button from 'src/theme/overrides/Button';


// ----------------------------------------------------------------------

export default function SessionForm() {
  // const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const WhiteRadio = styled(Radio)({
    color: 'white',
    '&.Mui-checked': {
      color: 'white',
    },
  });

  const { user } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const SessionSchema = Yup.object().shape({
    person: Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      // idNumber: Yup.string().required('ID Number is required'),
      gender: Yup.string().oneOf(['M', 'F'], 'Invalid Gender').required('Gender is required'),
      // dateOfBirth: Yup.date().nullable().required('Date of Birth is required'),
    }),
    document: Yup.object().shape({
      type: Yup.string().required('Document Type is required'),
      country: Yup.string().required('Country is required'),
    }),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  // Custom event handler for idNumber field
  // const handleIdNumberChange = (event) => {
  //   const numericValue = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  //   formik.setFieldValue('person.idNumber', numericValue);
  // };

  // Example array of options
  const options = [
    { value: 'PASSPORT', label: 'PASSPORT' },
    { value: 'ID_CARD', label: 'ID CARD' },
    { value: 'DRIVERS_LICENSE', label: 'DRIVERS LICENSE' },
    { value: 'RESIDENCE_PERMIT', label: 'RESIDENCE PERMIT' },
    // ... add more options as needed
  ];

  const initialValues = {
    person: {
      firstName: '',
      lastName: '',
      gender: 'F',
      // dateOfBirth: null,
    },
    document: {
      type: '',
      country: '',
    },
    address: {
      fullAddress: '',
    },
    vendorData: '' || 'Postman test',
  };

  const formik = useFormik({
    initialValues,

    validationSchema: SessionSchema,

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      console.log('values --------------- : ', values);
      setTimeout(() => {
        CreateSession({ ...values , vendorData : user ? user?._id : null})
          .then((res) => {
            console.log('Successfully data saved: ', res.data);
            disableLoading();
            if (res.data.status === 'success') {
              console.log('res.data.verification.sessionToken: ', res.data.verification.sessionToken);
              localStorage.setItem('session-token', res.data.verification.sessionToken);
              localStorage.setItem('sessionId', res.data.verification.id);
              navigate('/front', { replace: true });
            } else {
              // enqueueSnackbar('Invalid email or pwd!', {
              // 	variant: 'error',
              // });
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(false);
          });
      }, 1500);
    },
  });

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ mb: 4, mt: 8 }}>
          <Typography variant="h4" sx={{ color: 'white' }}>
            Verification Process
          </Typography>
        </Box>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                id="person.firstName"
                name="person.firstName"
                type="text"
                label={formik.touched.person?.firstName && Boolean(formik.errors.person?.firstName) ? '' : 'First Name'}
                placeholder="First Name"
                value={formik.values.person.firstName}
                onChange={formik.handleChange}
                error={formik.touched.person?.firstName && Boolean(formik.errors.person?.firstName)}
                helperText={formik.touched.person?.firstName && formik.errors.person?.firstName}
                InputProps={{
                  style: { color: 'white' },
                }}
                InputLabelProps={{
                  style: { color: 'white' },
                }}
              />

              <TextField
                id="person.lastName"
                name="person.lastName"
                type="text"
                label={formik.touched.person?.lastName && Boolean(formik.errors.person?.lastName) ? '' : 'Last Name'}
                placeholder="Last Name"
                value={formik.values.person.lastName}
                onChange={formik.handleChange}
                error={formik.touched.person?.lastName && Boolean(formik.errors.person?.lastName)}
                helperText={formik.touched.person?.lastName && formik.errors.person?.lastName}
                InputProps={{
                  style: { color: 'white' },
                }}
                InputLabelProps={{
                  style: { color: 'white' },
                }}
              />

              {/* <TextField
                id="person.idNumber"
                name="person.idNumber"
                type="text"
                placeholder="NIN"
                value={formik.values.person.idNumber}
                onChange={handleIdNumberChange}
                error={formik.touched.person?.idNumber && Boolean(formik.errors.person?.idNumber)}
                helperText={formik.touched.person?.idNumber && formik.errors.person?.idNumber}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 15, // Adjust the maxLength according to your requirements
                }}
              /> */}

              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="date of birth"
                  value={formik.values.person.dateOfBirth || null}
                  onChange={(date) => formik.setFieldValue('person.dateOfBirth', date)}
                  textField={(props) => (
                    <TextField
                      {...props}
                      name="person.dateOfBirth"
                      error={formik.touched.person.dateOfBirth && Boolean(formik.errors.person.dateOfBirth)}
                      helperText={formik.touched.person.dateOfBirth && formik.errors.person.dateOfBirth}
                      variant="outlined"
                    />
                  )}
                  format="YYYY-MM-DD"
                />
              </LocalizationProvider> */}

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="female"
                name="gender"
                value={formik.values.person.gender}
                onChange={(event) => formik.setFieldValue('person.gender', event.target.value)}
                sx={{ color: 'white' }}
              >
                <FormControlLabel
                value="F"
                control={<WhiteRadio />}
                label={<Typography sx={{ color: 'white' }}>Female</Typography>}
                />
                <FormControlLabel
                  value="M"
                  control={<WhiteRadio />}
                  label={<Typography sx={{ color: 'white' }}>Male</Typography>}
                />
              </RadioGroup>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-required-label" sx={{ color: 'white' }} >documentType</InputLabel>
                <Select
                  name="documentType" // Add the name attribute
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={formik.values.document.type}
                  label="Document Type *"
                  onChange={(event) => formik.setFieldValue('document.type', event.target.value)}
                  inputProps={{ style: { color: 'white' } }} // Set text color to white
                  style={{ color: 'white' }} // Set selected value color to white
                >
                  {options.map((option) => (
                    <MenuItem
                    key={option.value}
                    value={option.value}
                    style={option.value === formik.values.document.type ? { color: 'white' } : {}}
                     >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ color : 'white'}}>Required</FormHelperText>
              </FormControl>
              

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-required-label"sx={{ color : 'white'}} >Country</InputLabel>
                <Select
                  name="country" // Add the name attribute
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={formik.values.document.country}
                  label="Country *"
                  onChange={(event) => formik.setFieldValue('document.country', event.target.value)}
                  inputProps={{ style: { color: 'white' } }} // Set text color to white
                  style={{ color: 'white' }} // Set selected value color to white
                >
                  {Object.entries(Country).map(([key, value]) => (
                    <MenuItem 
                      key={value} 
                      value={value}
                      style={value === formik.values.document.country ? { color: 'white' } : {}}
                    >
                      {key}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ color : 'white'}} >Required</FormHelperText>
              </FormControl>
            </Stack>

            <LoadingButton
              sx={{ mt: 2 }}
              fullWidth
              size="large"
              type="submit"
              // disabled={formik.isSubmitting}
              variant="contained"
            >
              {loading ? (
                <span>
                  <CircularProgress size="1.5rem" color="inherit" />
                </span>
              ) : (
                <Box>Get Verify...</Box>
              )}
            </LoadingButton>
          </form>
        </Box>
      </Box>
    </>
  );
}
