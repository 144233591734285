import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Box, Modal } from '@mui/material';

const PromotionCard = styled(Card)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#222',
  padding: '20px',
  color: '#fff',
  backgroundImage: `url(https://enftx-html.vercel.app/images/bg/promo.png)`,
});

const Title = styled(Typography)({
  marginBottom: '10px',
});

const ExploreButton = styled(Button)({
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  padding: '10px 20px',
  marginRight: '10px',
  textDecoration: 'none',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#0056b3',
  },
});

const CreateButton = styled(Button)({
  backgroundColor: '#6c757d',
  color: '#fff',
  border: 'none',
  padding: '10px 20px',
  textDecoration: 'none',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#4d5458',
  },
});

const NFTPromotion = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (
    <PromotionCard>
      <CardContent>
        <Title variant="h5">Click on the below button to check Balance</Title>
        <ExploreButton
          sx={{
            mb: 11,
          }}
          onClick={handleOpen}
          variant="contained"
        >
          Check Balance
        </ExploreButton>
        {/* <Button onClick={handleOpen}>Check Blance</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Your Current Balance
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              $ {props.total ? props.total :0}
            </Typography>
          </Box>
        </Modal>
        {/* <Typography variant="body1">Digital marketplace for crypto collectibles and non-fungible tokens</Typography> */}

        {/* <CreateButton href="#" variant="contained">Create</CreateButton> */}
      </CardContent>
    </PromotionCard>
  );
};

export default NFTPromotion;
