import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const ProtectedRoutes = ({ checkKey ,Component  , nextUrl}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const sessionToken = localStorage.getItem(checkKey);
    if (!sessionToken) {
      navigate(nextUrl);
    }
  }, [navigate]);

  return localStorage.getItem(checkKey) ? (
    <>
      <Box className="addBackgroundImage">{Component}</Box>
    </>
  ) : (
    <Navigate to={nextUrl} replace />
  );
};

ProtectedRoutes.propTypes = {
  Component: PropTypes.node.isRequired,
};

export default ProtectedRoutes;
