/* eslint-disable */
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ChangeEvent, MouseEvent, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import EyeOutline from 'mdi-material-ui/EyeOutline';
// import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { getUserData } from 'src/redux/userSession';

const MIN_PASSWORD_LENGTH = 8;

// import signup from
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Box,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { LoadingButton } from '@mui/lab';

import InfoSetup from '../../api/signup';
// ----------------------------------------------------------------------

export const SignUp = () => {
  // const router = useRouter();
  // const { email } = router.query;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const SignUpSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required('Email is required'),
    // referralCode: Yup.string().max(9, 'Invalid code'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/^(?=.*[A-Z])(?=.*\d).*$/, 'Password must contain at least 1 uppercase letter and 1 number')
      .required('Password is required'),
  });

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const image = 'abc.jpg';
  const formik = useFormik({
    initialValues: {
      email: '',
      referralCode: '',
      password: '',
      // confirmPassword: '',
    },
    validationSchema: SignUpSchema,

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log('email: ', values.email?.toString().toLowerCase());
      const input = {
        email: values.email?.toString().toLowerCase(),
        // referralCode: values.referralCode.toString(),
        password: values.password.toString(),
        role: 'personal',
        // profileImage: image.toString(),
      };

      if (values.referralCode.toString()) {
        input.referralCode = values.referralCode.toString();
      }

      enableLoading();
      setTimeout(() => {
        InfoSetup({
          ...input,
        })
          .then((res) => {
            if (res?.data) {
              disableLoading();
              formik.resetForm();

              console.log('res.data: ', res.data);
              if (res?.data?.status === 200) {
                enqueueSnackbar('Info Setup Successfully!', {
                  variant: 'success',
                });
                dispatch(getUserData(res?.data?.data?.user));
                navigate(`/questionnairies/${res?.data?.data?.user?._id}`, { replace: true });
              } else if (res?.data?.status === 403) {
                enqueueSnackbar('User Already Exist With This Email!', {
                  variant: 'error',
                });
              } else {
                enqueueSnackbar('User Not Found!', {
                  variant: 'error',
                });
              }
            }
          })
          .catch(() => {
            formik.resetForm();
            disableLoading();
            setSubmitting(false);
            setStatus(false);
            enqueueSnackbar('Something went wrong!', {
              variant: 'error',
            });
          });
      }, 1500);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={1} direction="column" alignItems="center">
          <Box paddingBottom={'22px'}>
            <TextField
              id="email"
              name="email"
              type="email"
              placeholder="Enter an Email"
              // disabled={true}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{
                sx: {
                  width: { xs: 320, sm: 480 },
                  height: { xs: 42, sm: 48 },
                  color: 'white',
                },
              }}
            />
          </Box>

          <Box paddingBottom={'22px'}>
            <TextField
              id="referralCode"
              name="referralCode"
              type="text"
              placeholder="Referral Code"
              value={formik.values.referralCode}
              onChange={formik.handleChange}
              // inputProps={{
              //   maxLength: 9, // Limit input to a maximum of 9 characters
              // }}
              error={formik.touched.referralCode && Boolean(formik.errors.referralCode)}
              helperText={formik.touched.referralCode && formik.errors.referralCode}
              InputProps={{
                sx: {
                  width: { xs: 320, sm: 480 },
                  height: { xs: 42, sm: 48 },
                  color: 'white',
                },
              }}
            />
            <Box sx={{ width: { xs: 320, sm: 480 }, height: { xs: 42, sm: 48 } }} position="relative">
              <Typography
                fontSize={12}
                sx={{
                  display: 'flex',
                  color: 'secondary.light',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  color: 'white',
                }}
              >
                optional
              </Typography>
            </Box>
          </Box>

          <Box paddingBottom={'28px'}>
            <Tooltip
              title={
                <Box>
                  <Box display="flex" alignItems="center">
                    {formik.values.password.length >= MIN_PASSWORD_LENGTH ? (
                      <CheckIcon sx={{ color: 'green' }} />
                    ) : (
                      <CloseIcon sx={{ color: 'red' }} />
                    )}
                    <Box ml={1}>Minimum {MIN_PASSWORD_LENGTH} characters</Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {/[0-9]/.test(formik.values.password) ? (
                      <CheckIcon sx={{ color: 'green' }} />
                    ) : (
                      <CloseIcon sx={{ color: 'red' }} />
                    )}
                    <Box ml={1}>At least 1 number</Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {/[A-Z]/.test(formik.values.password) ? (
                      <CheckIcon sx={{ color: 'green' }} />
                    ) : (
                      <CloseIcon sx={{ color: 'red' }} />
                    )}
                    <Box ml={1}>At least 1 upper case</Box>
                  </Box>
                </Box>
              }
              placement={isSmallScreen ? 'bottom' : 'right'}
              arrow
            >
              <TextField
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter a Password"
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOffIcon /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    width: { xs: 320, sm: 480 },
                    height: { xs: 42, sm: 48 },
                    color: 'white',
                  },
                }}
                autoComplete="current-password"
              />
            </Tooltip>
          </Box>
        </Stack>

        <LoadingButton
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            width: { xs: 320, sm: 480 },
            marginLeft: { xs: 0, sm: '-20px' },
          }}
        >
          <Box>
            {loading ? (
              <span>
                <CircularProgress size="1.5rem" color="inherit" />
              </span>
            ) : (
              <span> Sign Up</span>
            )}
          </Box>
        </LoadingButton>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link sx={{ cursor: 'pointer' }} component={''} variant="subtitle2" onClick={() => navigate('/login')}>
            Already Registered? Login
          </Link>
        </Stack>
      </form>
    </>
  );
};
