// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Button, Box, CardActions, CardContent } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  // total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({
  title,
  body,
  buttonOneLabel,
  buttonTwoLabel,
  icon,
  color = 'primary',
  sx,
  ...other
}) {
  return (
    <Card
      sx={{
        // py: 5,
        boxShadow: 0,
        // textAlign: 'center',
        padding: '20px',
        // color: (theme) => theme.palette[color].darker,
        // bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <CardContent style={{ paddingLeft: '8px' }}>
        <Typography variant="h6">{'FUND YOUR ACCOUNT'}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="subtitle1">{'BNB Smart Chain (BEP20):'}</Typography>
          <Typography variant="body1">{'0xab9dc298a6254e9a51016047a3c142fc87156293'}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="subtitle1">{'ETH Ethereum (ERC20):'}</Typography>
          <Typography variant="body1">{'0xab9dc298a6254e9a51016047a3c142fc87156293'}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="subtitle1">{'BTC Binance:'}</Typography>
          <Typography variant="body1">{'1Fk3UW4jfExad4rxTtmfcqj5zfjp78MHVr'}</Typography>
        </Box>
      </CardContent>
      {/* <CardActions>
       
      </CardActions> */}
    </Card>
  );
}
