import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

AppConversionRates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};

export default function AppConversionRates({ title, subheader, chartData, ...other }) {
  const [totalInvest, setTotalInvest] = useState(0);

  const chartLabels =  chartData.length > 0 ? chartData?.map((i) => i.date) : [];


  useEffect(() => {
    const calculatedTotalDeposit = chartData.length > 0 ? chartData.reduce((accumulator, trade) => accumulator + trade.deposit, 0) : "0";
    setTotalInvest(calculatedTotalDeposit);
  }, [chartData]);

  const chartSeries = chartData.length > 0 ? chartData.map((i) => ((i?.deposit / totalInvest) * 100).toString()): [];

  const chartOptions = useChart({
    tooltip: {
      marker: { show: true },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
    },
    markers: {
      size: 5,
      // colors: ['#FFA41B'],
      strokeColors: '#fff',
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    plotOptions: {
      markers: { show: true, size: [4, 7] },
      // bar: { horizontal: true, barHeight: '28%', borderRadius: 2 },
    },
    xaxis: {
      categories: chartLabels,
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} />
      <Box sx={{ mx: 3 }} dir="ltr">
        { (chartSeries && !chartSeries.includes('Infinity')) && <ReactApexChart type="line" series={[{ data: chartSeries }]} options={chartOptions} height={364} />}
      </Box>
    </Card>
  );
}
