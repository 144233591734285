/* eslint-disable */
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ChangeEvent, MouseEvent, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import EyeOutline from 'mdi-material-ui/EyeOutline';
// import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { getUserData } from '../../redux/userSession';

const MIN_PASSWORD_LENGTH = 8;

// import signup from
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Box,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { LoadingButton } from '@mui/lab';

import ForgotPassword from '../../api/forgotpass';

export const ForgotPass = () => {
  // const resetPassword = '/forgot-password';
  	const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(5, 'Minimum 5 symbols')
      .max(40, 'Maximum 40 symbols')
      .required('Email is required'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const clearFields = () => {
    formik.resetForm(); // Reset form fields
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,

    // onSubmit: (values) => {
    // 	alert(JSON.stringify(values, null, 2));
    // },

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        ForgotPassword(values.email)
          .then((res) => {
            disableLoading();
            if (res.status === 200 && res.data?.data) {
              enqueueSnackbar('Send OTP to Email successfully!', {
                variant: 'success',
              });
              clearFields();
              console.log("resend otp: res-> ",res?.data?.data?.user)
              dispatch(getUserData(res?.data?.data));
              navigate('/verify-otp', { replace: true });
              // storeCookies(res?.data?.data?.token);
            } else {
              enqueueSnackbar(`Invalid email OTP can't send!`, {
                variant: 'error',
              });
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(false);
          });
      }, 1500);
    },

    // onSubmit: async()=> {
    //   alert('Submit');
    // }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            id="email"
            name="email"
            type="text"
            placeholder="Email address"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputProps={{
              style: { color: 'white' },
              inputProps: {
                style: { color: 'white' },
              },
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            // disabled={formik.isSubmitting}
            variant="contained"
            // onClick={handleClick}
          >
            {loading ? (
              <span>
                <CircularProgress size="1.5rem" color="inherit" />
              </span>
            ) : (
              <Box>Next</Box>
            )}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
};
