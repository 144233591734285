/* eslint-disable */

import { Box, Container } from '@mui/material';
import React from 'react';
import { ForgotPass } from 'src/components/auth/forgotPass';

function ForgotPassSection() {
  return (
    <>
      <Container maxWidth={false}>
        <ForgotPass />
      </Container>
    </>
  );
}

export default ForgotPassSection;
