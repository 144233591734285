import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
// import useResponsive from '../hooks/useResponsive';
// components
// import Logo from '../components/logo';
// import Iconify from '../components/iconify';
// sections
import { SessionForm } from '../sections/kyc';

export default function VerifyKyc() {
  // const mdUp = useResponsive('up', 'md');

  const location = useLocation();

  useEffect(() => {
    const rootElement = document.getElementById('root');
    rootElement.className = 'backgroundImage'; // Set class based on the route (remove the leading "/")
  }, [location.pathname]);

  const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      // display: 'flex',
    },
  }));
  return (
    <>
      <Box className="addBackgroundImage">
        <Helmet>
          <title> IAESIR AI - Verify your account </title>
        </Helmet>
        <StyledRoot>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <SessionForm />
          </Box>
        </StyledRoot>
      </Box>
    </>
  );
}
