/* eslint-disable */

import { Box, Container } from '@mui/material';
import React from 'react';
import { ResetPass } from 'src/components/auth/resetPass';

function ResetPassSection() {
  return (
    <>
      <Container maxWidth={false}>
        <ResetPass />
      </Container>
    </>
  );
}

export default ResetPassSection;
