import { enqueueSnackbar, useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  FormHelperText, // Import FormHelperText
} from '@mui/material';
import * as Yup from 'yup';
import QuestionnaireisSetup from '../../api/questionnairies';


const Question = ({ formik, name, label, options }) => {
  const isControlled = formik.values[name] !== undefined;

  return (
    <FormControl component="fieldset" style={{ color: 'white' }}>
      <Typography variant="subtitle1" style={{ color: 'white' }}>
        {label}
      </Typography>
      {isControlled ? (
        <RadioGroup name={name} value={formik.values[name]} onChange={formik.handleChange}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={<span style={{ color: 'white' }}>{option.label}</span>}
            />
          ))}
        </RadioGroup>
      ) : (
        <RadioGroup name={name}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={<span style={{ color: 'white' }}>{option.label}</span>}
            />
          ))}
        </RadioGroup>
      )}
      {/* Display error message for radio button group */}
      {name === 'questionRadioGroup' && formik.touched[name] && formik.errors[name] && (
        <FormHelperText error>{formik.errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

const initialValues = {
  question1: '',
  question2: '',
  question3: '',
  question4: '',
  question5: '',
  question6: '',
  question7: '',
  question8: '',
  question9: '',
};


const validationSchema = Yup.object().shape({
  question1: Yup.string().required('This field is required'),
  question2: Yup.string().required('This field is required'),
  question3: Yup.string().required('This field is required'),
  question4: Yup.string().required('This field is required'),
  question5: Yup.string().required('This field is required'),
  question6: Yup.string().required('This field is required'),
  question7: Yup.string().required('This field is required'),
  question8: Yup.string().required('This field is required'),
  question9: Yup.string().required('This field is required'),
  // Add other validation rules for other fields here

  // // Custom validation for radio button groups
  // questionRadioGroup: Yup.string().test(
  //   'isRadioSelected',
  //   'Select an option', // Error message to display
  //   (value) => {
  //     // Check if any radio button option is selected
  //     return (
  //       value === 'Yeah' || // Modify to match the value of the default selected option
  //       value === 'No' // Modify to match the value of the other option
  //     );
  //   }
  // ),
});



export default function ProfessionalClientQuestionnaireForm(){
  const navigate = useNavigate();
  const { userId } = useParams()
  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  

  const formik = useFormik({
    initialValues,
    validationSchema, // Assign the validation schema

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const input = {
        userId,
       ...values
      };

      enableLoading();
      setTimeout(() => {
        QuestionnaireisSetup({
          ...input,
        })
          .then((res) => {
            if (res?.data) {
              disableLoading();
              formik.resetForm();

              console.log('res.data: ', res.data);
              if (res?.data?.status === 200) {
                enqueueSnackbar('Questionairies Saved Successfully!', {
                  variant: 'success',
                });
                navigate('/verification', { replace: true });
              } else if (res?.data?.status === 403) {
                enqueueSnackbar('User Already Exist With This Email!', {
                  variant: 'error',
                });
              } else {
                enqueueSnackbar('Questionairies Not Found!', {
                  variant: 'error',
                });
              }
            }
          })
          .catch(() => {
            formik.resetForm();
            disableLoading();
            setSubmitting(false);
            setStatus(false);
            enqueueSnackbar('Something went wrong!', {
              variant: 'error',
            });
          });
      }, 1500);
    },
  });

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom style={{ color: 'white' }}>
        Professional/Non-Professional Client Questionnaire
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        {/* Question 1 */}
        <Question
          formik={formik}
          name="question1"
          label="1. Do you know what a digital asset (Tokens) is and the risks that investing in them entails?"
          options={[
            { value: 'Yeah', label: 'Yeah' },
            { value: 'No', label: 'No' },
          ]}
        />
        {/* Display validation error for Question 1 */}
        {formik.touched.question1 && formik.errors.question1 && (
          <div style={{ color: 'red' }}>{formik.errors.question1}</div>
        )}

        {/* Question 2 */}
        <Question
          formik={formik}
          name="question2"
          label="2. Do you know that by investing in digital assets you can lose up to 100% of your investment?"
          options={[
            { value: 'Yeah', label: 'Yeah' },
            { value: 'No', label: 'No' },
          ]}
        />
        {formik.touched.question2 && formik.errors.question2 && (
          <div style={{ color: 'red' }}>{formik.errors.question2}</div>
        )}
        {/* 3. What is the source of most of your recurring income? */}
        {/* Question 3 */}
        <Question
          formik={formik}
          name="question3" // Corrected name to "question3"
          label="3. What is the source of most of your recurring income?"
          options={[
            { value: 'NoRegularIncome', label: 'I have no regular income' },
            { value: 'RetirementOrDisabilityBenefit', label: 'Retirement or disability benefit' },
            { value: 'WorkActivity', label: 'Work activity' },
            { value: 'IncomeFromRealEstate', label: 'Income from owned real estate' },
            { value: 'FinancialInvestmentReturns', label: 'Financial investment returns' },
          ]}
        />
        {formik.touched.question3 && formik.errors.question3 && (
          <div style={{ color: 'red' }}>{formik.errors.question3}</div>
        )}
        {/* Question 4 */}
        <Question
          formik={formik}
          name="question4" // Name should be "question4"
          label="4. What is the origin of the capital you want to invest or reinvest in these types of products?"
          options={[
            { value: 'RepositionExistingInvestments', label: 'Reposition existing financial investments' },
            { value: 'InheritanceOrDonation', label: 'Inheritance or donation' },
            { value: 'BusinessIncome', label: 'Business income' },
            { value: 'OtherOrigin', label: 'Other origin' },
          ]}
        />
        {formik.touched.question4 && formik.errors.question4 && (
          <div style={{ color: 'red' }}>{formik.errors.question4}</div>
        )}

        {/* Question 5 */}
        <Question
          formik={formik}
          name="question5" // Name should be "question5"
          label="5. What level of experience do you have in digital asset investments and investments?"
          options={[
            { value: 'NoExperience', label: 'I do not have experience' },
            { value: 'LessThanAYear', label: 'Less than a year' },
            { value: 'TwoToFiveYears', label: 'From two to 5 years' },
            { value: 'GreaterThanFiveYears', label: 'Greater than 5 years' },
          ]}
        />
        {formik.touched.question5 && formik.errors.question5 && (
          <div style={{ color: 'red' }}>{formik.errors.question5}</div>
        )}

        {/* Question 6 */}
        <Question
          formik={formik}
          name="question6" // Name should be "question6"
          label="6. What training do you have in this type of investments?"
          options={[
            { value: 'None', label: 'None' },
            { value: 'Basic', label: 'Basic' },
            { value: 'Advanced', label: 'Advanced' },
          ]}
        />
        {formik.touched.question6 && formik.errors.question6 && (
          <div style={{ color: 'red' }}>{formik.errors.question6}</div>
        )}

        {/* Repeat similar Question components for other questions */}
        <Question
          formik={formik}
          name="question7"
          label="7. Have you held public functions in recent years or are you currently holding any public function? Additionally, mention if you are close to people who have this condition."
          options={[
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ]}
        />
        {formik.touched.question7 && formik.errors.question7 && (
          <div style={{ color: 'red' }}>{formik.errors.question7}</div>
        )}
        {/* {formik.values.question7 === 'Yes' && (
          <TextField
            name="pepDetails"
            label="Specify details"
            value={formik.values.pepDetails}
            onChange={formik.handleChange}
            fullWidth
          />
        )} */}
        <Question
          formik={formik}
          name="question8"
          label="8. Do you plan to channel other people's funds through this mandate?"
          options={[
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ]}
        />
        {formik.touched.question8 && formik.errors.question8 && (
          <div style={{ color: 'red' }}>{formik.errors.question8}</div>
        )}
        {/* {formik.values.question8 === 'Yes' && (
          <TextField
            name="fundChannelDetails"
            label="Specify details (Fill in and then, Valid response)"
            value={formik.values.fundChannelDetails}
            onChange={formik.handleChange}
            fullWidth
          />
        )} */}
        <Question
          formik={formik}
          name="question9"
          label="9. Annual income"
          options={[
            { value: '1To9999', label: '€1 to €9,999 annually' },
            { value: '10000To30000', label: '€10,000 to €30,000 annually' },
            { value: '30000To50000', label: '€30,000 to €50,000 annually' },
            { value: '50000To100000', label: '€50,000 to €100,000 annually' },
            { value: 'MoreThan100000', label: 'More than €100,000 annually' },
          ]}
        />
        {formik.touched.question9 && formik.errors.question9 && (
          <div style={{ color: 'red' }}>{formik.errors.question9}</div>
        )}
        {/* ... */}
        {/* ... */}
        <div style={{ marginTop: 'auto' }}>
          {/* Add 'marginTop: 'auto'' to push the button to the bottom of the form */}
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </div>
      </form>
    </Container>
  );
};
