/* eslint-disable */
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ChangeEvent, MouseEvent, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import EyeOutline from 'mdi-material-ui/EyeOutline';
// import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import Iconify from '../iconify/Iconify';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { getUserData } from '../../redux/userSession';

const MIN_PASSWORD_LENGTH = 8;

// import signup from
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Box,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { LoadingButton } from '@mui/lab';
//api
import resetPassword from 'src/api/resetPass';


export const ResetPass = () => {
  // const resetPassword = '/forgot-password';
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
    .min(MIN_PASSWORD_LENGTH, `Minimum ${MIN_PASSWORD_LENGTH} symbols`)
    .max(30, 'Maximum 30 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const clearFields = () => {
    formik.resetForm(); // Reset form fields
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: LoginSchema,

    // onSubmit: (values) => {
    // 	alert(JSON.stringify(values, null, 2));
    // },

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        resetPassword( user ? user.user._id : "" , values.password)
          .then((res) => {
            disableLoading();
            if (res.status === 200 && res.data?.data) {
              enqueueSnackbar('Reset Password Successfully!', {
                variant: 'success',
              });
              clearFields();
              console.log('resend otp: res-> ', res?.data?.data?.user);
              // dispatch(getUserData(res?.data?.data));
              navigate('/login', { replace: true });
              // storeCookies(res?.data?.data?.token);
            } else {
              enqueueSnackbar('Something went wrong while Reset Password!', {
                variant: 'error',
              });
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(false);
          });
      }, 1500);
    },

    // onSubmit: async()=> {
    //   alert('Submit');
    // }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            name="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              style: { color: 'white' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
          />

          <TextField
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              style: { color: 'white' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setshowConfirmPassword(!showConfirmPassword)} edge="end">
                    <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            // disabled={formik.isSubmitting}
            variant="contained"
            // onClick={handleClick}
          >
            {loading ? (
              <span>
                <CircularProgress size="1.5rem" color="inherit" />
              </span>
            ) : (
              <Box>Submit</Box>
            )}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
};
