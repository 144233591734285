import axios from 'axios';
import config from '../config/config';

const InfoSetup = async (signUpPayload) => {
  try {
    const data = {
      ...signUpPayload,
    };

    const url = `${config.backendUrl}/v1/main/users/signup`;
    return await axios({
      method: 'POST',
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
        // 'X-AUTH-CLIENT': config.API_TOKEN,
        // 'X-HMAC-SIGNATURE': config.generateSignature(data , config.API_SECRET )
      },
    });
  } catch (error) {
    console.log("error: ",error)
    if(error.code === "ERR_BAD_REQUEST"){
      return error.response
    }
    return {
      status: 404,
    };
  }
};
export default InfoSetup;
