// component
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
// import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <GridViewRoundedIcon />,
  },

  // {
  //   title: 'Wallet',
  //   path: '/dashboard/user',
  //   icon: <AccountBalanceWalletOutlinedIcon />,
  // },

  // {
  //   title: 'User',
  //   path: '/dashboard/user',
  //   icon: <PersonOutlineIcon />,
  // },

  {
    title: 'Wallet',
    path: '/dashboard/user-wallet',
    icon: <AccountBalanceWalletOutlinedIcon />,
  },

  {
    title: 'Trade',
    path: '/dashboard/pair-trade',
    icon: <CurrencyExchangeIcon />,
  },

  // {
  //   title: 'LightMode',
  //   path: '/dashboard/products',
  //   icon: <LightModeOutlinedIcon />,
  // },
    {
    title: 'Profile',
    path: '/dashboard/profile',
    icon: <AccountBoxOutlinedIcon />,
  },
  // {
  //   title: 'Profile',
  //   path: '/dashboard/blog',
  //   icon: <AccountBoxOutlinedIcon />,
  // },
  // {
  //   title: 'settings',
  //   path: '/login',
  //   icon: <SettingsOutlinedIcon />,
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
