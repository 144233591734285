import axios from 'axios';
import config from '../config/config';

const verifyOTP = async ( otp , userId ) => {
  try {
    const data ={
      otp, 
      userId
    }
    console.log("here we are : ",data)

    const url = `${config.backendUrl}/v1/main/users/verify-otp`;
    return await axios({
      method: 'POST',
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.log("error: ",error)
    if(error.code === "ERR_BAD_REQUEST"){
      return error.response
    }
    return {
      status: 404,
    };
  }
};
export default verifyOTP;
