import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
// import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
// import UserPage from './pages/UserPage';
import NewUser from './pages/NewUser';
import UserWallet from './pages/UserWallet';
import PairTrade from './pages/PairTrade';

// import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import VerifyKyc from './pages/verifyKyc';
import CardScanner from './sections/kyc/CardScanner/CardScanner';
import BackDocument from './sections/kyc/BackDocument/BackDocument';
import Selfie from './sections/kyc/Selfie/Selfie';
import Decision from './sections/kyc/Decision/Decision';
import ProtectedRoutes from './protectedRoutes';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import VerifyOTPPage from './pages/VerifyOTPPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import QuestionaPage from './pages/Questionnairies';
import UserProfile from './pages/userProfile';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          element: (
            <ProtectedRoutes
              checkKey={'access-token'}
              Component={<Navigate to="/dashboard/app" />}
              nextUrl={'/login'}
            />
          ),
          index: true,
        },

        { path: 'app', element: <DashboardAppPage /> },
        { path: 'profile', element: <UserProfile/>},
        // { path: 'user', element: <UserPage /> },     // first user complete table
        { path: 'user', element: <NewUser /> },

        { path: 'user-wallet', element: <UserWallet /> },

        { path: 'pair-trade', element: <PairTrade /> },

        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/',
      element: <SignUpPage />,
    },
    {
      path: '/questionnairies/:userId',
      element: <QuestionaPage />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: '/verify-otp',
      element: <VerifyOTPPage />,
    },
    {
      path: '/reset-password',
      element: <ResetPasswordPage />,
    },
    {
      path: '/verification',
      element: <VerifyKyc />,
      children: [
        { element: <Navigate to="section" />, index: true },
        { path: '404', element: <Page404 /> },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   path: "/dashboard/app",
    //   element: <ProtectedRoutes Component={<SimpleLayout/>}  />,
    // },
    {
      path: '/front',
      element: <ProtectedRoutes checkKey={'session-token'} Component={<CardScanner />} nextUrl={'/verification'} />,
    },
    {
      path: '/back',
      element: <ProtectedRoutes checkKey={'session-token'} Component={<BackDocument />} nextUrl={'/verification'} />,
    },
    {
      path: '/selfie',
      element: <ProtectedRoutes checkKey={'session-token'} Component={<Selfie />} nextUrl={'/verification'} />,
    },
    {
      path: '/decision',
      element: <ProtectedRoutes checkKey={'session-token'} Component={<Decision />} nextUrl={'/verification'} />,
    },
    {
      path: '/404',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
