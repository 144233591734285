/* eslint-disable */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button , Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import VerifyOTPSection from 'src/sections/auth/VerifyOTPSection';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontFamily: 'Comfortaa, Sans-serif',
}));

// ----------------------------------------------------------------------

export default function VerifyOTPPage() {
  // const location = useLocation();

  // useEffect(() => {
  //   const rootElement = document.getElementById('root');
  //   console.log('location.pathname.substr(1): ', location.pathname.substr(1));
  //   rootElement.className = 'backgroundImage'; // Set class based on the route (remove the leading "/")
  // }, [location.pathname]);
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Box className="addBackgroundImage">
        <Helmet>
          <title>Verify OTP </title>
        </Helmet>
        <StyledRoot>
          <Container maxWidth="xl">
            <StyledContent>
              <Logo sx={{ width: 200, height: 200 }} />
              <StyledTypography variant="h4" textAlign="center" gutterBottom>
                Security Verification
              </StyledTypography>
              <VerifyOTPSection />
            </StyledContent>
          </Container>
        </StyledRoot>
      </Box>
    </>
  );
}
