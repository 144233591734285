import axios from 'axios';
import config from '../config/config';

const ForgotPassword = async ( email ) => {
  try {
    const data ={
      email,
      role: 'personal'
    }

    const url = `${config.backendUrl}/v1/main/users/forgot-password`;
    return await axios({
      method: 'POST',
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.log("error: ",error)
    if(error.code === "ERR_BAD_REQUEST"){
      return error.response
    }
    return {
      status: 404,
    };
  }
};
export default ForgotPassword;
