// import Api from '../utils/axios';
import axios from 'axios';
import config from '../config/config';

const CreateSession = async (payload) => {
  try {
		console.log("createSessionPayload: ",payload);   
		if(payload.person.dateOfBirth){
			payload.person.dateOfBirth = payload.person.dateOfBirth.toISOString().split('T')[0]
		}
    const data = {
      verification: {
        callback: payload?.callbackURL || 'https://veriff.me',
        person: {
          ...payload.person,
        },
        document: {
          ...payload?.document,
        },
        vendorData: payload?.vendorData ? payload?.vendorData : 'Postman test',
      },
    };


    const url = `${config.url}/v1/sessions/`;
    return await axios({
      method: 'POST',
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-CLIENT': config.API_TOKEN,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export default CreateSession;
