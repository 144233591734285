/* eslint-disable */
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ChangeEvent, MouseEvent, ReactNode, useState , useEffect  } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import EyeOutline from 'mdi-material-ui/EyeOutline';
// import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import CircularProgress from '@mui/material/CircularProgress';

const MIN_PASSWORD_LENGTH = 8;

// import signup from
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Box,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { LoadingButton } from '@mui/lab';

// import ForgotPassword from '../../api/forgotpass';
import verifyOTP from 'src/api/verifyOTP';
import ResendOTP from 'src/api/resendOTP';

export const VerifyOTP = () => {
  // const resetPassword = '/forgot-password';
  const [showResendButton, setShowResendButton] = useState(false);
  const [timer, setTimer] = useState(40);
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.user);


  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setShowResendButton(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const LoginSchema = Yup.object().shape({
    otp: Yup.string()
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const clearFields = () => {
    formik.resetForm(); // Reset form fields
  };

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: LoginSchema,

    // onSubmit: (values) => {
    // 	alert(JSON.stringify(values, null, 2));
    // },

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log("user: -> useSelector",  user.user._id , "  values.otp: ", values.otp);
      enableLoading();
      setTimeout(() => {
        verifyOTP(values.otp , user ? user.user._id : "")
          .then((res) => {
            disableLoading();
            if (res.status === 200 ) {
              enqueueSnackbar('OTP verified successfully!', {
                variant: 'success',
              });
              navigate('/reset-password', { replace: true });
              clearFields();
              // dispatch(getUserData(res?.data?.data));
              // storeCookies(res?.data?.data?.token);
            } else {
              enqueueSnackbar('Invalid email!', {
                variant: 'error',
              });
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(false);
          });
      }, 1500);
    },

    // onSubmit: async()=> {
    //   alert('Submit');
    // }
  });

  const handleResendButtonClick= () => {
    setTimeout(() => {
      ResendOTP(values.email)
        .then((res) => {
          disableLoading();
          if (res.status === 200 && res.data?.data?.user) {
            enqueueSnackbar('OTP send to your Email!', {
              variant: 'success',
            });
            // navigate('/verify-otp', { replace: true });
            clearFields();
            // dispatch(getUserData(res?.data?.data));
            // storeCookies(res?.data?.data?.token);
          } else {
            enqueueSnackbar('Invalid email!', {
              variant: 'error',
            });
          }
        })
        .catch(() => {
          disableLoading();
          setSubmitting(false);
          setStatus(false);
        });
    }, 1500);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            id="otp"
            name="otp"
            type="text"
            placeholder="Enter OTP"
            value={formik.values.otp}
            onChange={formik.handleChange}
            error={formik.touched.otp && Boolean(formik.errors.otp)}
            helperText={formik.touched.otp && formik.errors.otp}
            InputProps={{
              style: { color: 'white' },
              inputProps: {
                style: { color: 'white' },
              },
              endAdornment: (
                <InputAdornment position="end">
                  {showResendButton ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleResendButtonClick}
                    >
                      Resend OTP
                    </Button>
                  ) : (
                    <span>Get Code in {timer} s</span>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            // disabled={formik.isSubmitting}
            variant="contained"
            // onClick={handleClick}
          >
            {loading ? (
              <span>
                <CircularProgress size="1.5rem" color="inherit" />
              </span>
            ) : (
              <Box>Next</Box>
            )}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
};
