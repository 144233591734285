import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

export const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.fullName(),
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'banned']),
  role: sample(['Leader', 'Hr Manager']),
}));

// export const newUsers = [...Array(2)].map((_, index) => ({
//   id: faker.datatype.uuid(),
//   avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,

//   userEmail: sample(['luis.gemio@defai.eu', 'david.ventura@defai.eu']),

//   password: sample(['kdnvfdjefrlk524defwnf', 'hskñlfdhufe2265djwl']),
// }));

export const newUsers = [
  {
    id: faker.datatype.uuid(),
    avatarUrl: `/assets/images/avatars/avatar_1.jpg`,
    userEmail: 'luis.gemio@defai.eu',
    password: 'kdnvfdjefrlk524defwnf',
  },
  {
    id: faker.datatype.uuid(),
    avatarUrl: `/assets/images/avatars/avatar_2.jpg`,
    userEmail: 'david.ventura@defai.eu',
    password: 'hskñlfdhufe2265djwl',
  },
];

export const userWallet = [
  {
    id: faker.datatype.uuid(),
    walletAddress: 'jhnicPwy97BCR8Ab0OSSy3iZnlEGqAt4qS8Wl3VKqgGvAkQ665JSaMpXtMaR3j9p',
    fundsTransferred: '5000 usdt',
    adminWallet: 'Pzn4kognrIZSsN7iV1m2JTf0K9Xgfq3PomtNEseylfkPFG1qR6W2lGzxMkfWQDbF',
  },
  {
    id: faker.datatype.uuid(),
    walletAddress: 'BHMW9C9A17WZLvE0eItTG219aOezTyfKTc3OBMroGKYv0O28phPffRznpLAmrZ5T',
    fundsTransferred: '600 usdt',
    adminWallet: 'Pzn4kognrIZSsN7iV1m2JTf0K9Xgfq3PomtNEseylfkPFG1qR6W2lGzxMkfWQDbF',
  },
];

export const trade = [
  {
    id: faker.datatype.uuid(),
    pair: 'ETHUSDT',
    date: '15/08/2023',
    time: '21:15',
    profit: 68,
    deposit: 600,
    withdraw: 10,
    balance: 658,
  },
  {
    id: faker.datatype.uuid(),

    pair: 'BNBUSDT',
    date: '21/09/2023',
    time: '21:15',
    profit: 78,
    deposit: 700,
    withdraw: 10,
    balance: 768,
  },

  {
    id: faker.datatype.uuid(),

    pair: 'LTCUSDT',
    date: '18/08/2023',
    time: '21:15',
    profit: 50,
    deposit: 900,
    withdraw: 10,
    balance: 940,
  },

  {
    id: faker.datatype.uuid(),

    pair: 'BTCUSDT',
    date: '05/08/2023',
    time: '13:30',
    profit: 350,
    deposit: 5000,
    withdraw: 150,
    balance: 5200,
  },

  {
    id: faker.datatype.uuid(),

    pair: 'DOTUSDT',
    date: '23/09/2023',
    time: '21:15',
    profit: 70,
    deposit: 800,
    withdraw: 10,
    balance: 860,
  },
];
