// import Api from '../utils/axios';
import axios from 'axios';
import config from '../config/config';

const SubmitSession = async (sessionId) => {
  try {

    const data = {
      verification: {
        "status": "submitted"
      },
    };


    const url = `${config.url}/v1/sessions/${sessionId}`;
    return await axios({
      method: 'PATCH',
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-CLIENT': config.API_TOKEN,
        'X-HMAC-SIGNATURE': config.generateSignature(data , config.API_SECRET )
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export default SubmitSession;
