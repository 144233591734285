import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { styled } from '@mui/material/styles';
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;

const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentSubject.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppCurrentSubject({ title, subheader, chartData, chartColors, chartLabels, ...other }) {
  // const chartOptions = useChart({
  //   stroke: { width: 2 },
  //   fill: { opacity: 0.48 },
  //   legend: { floating: true, horizontalAlign: 'center' },
  //   xaxis: {
  //     categories: chartLabels,
  //     labels: {
  //       style: {
  //         colors: chartColors,
  //       },
  //     },
  //   },
  // });

  const options = {
    labels: ["Artwork Sold", "Artwork Cancel", "Neither"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          // size: '50%',
          labels: {
            show: false,
            name: {
              //   ...
              // },
              // value: {
              //   ...
              // }
            },
          },
        },
      },
    },
    legend: {
      position: 'bottom',
      // offsetY: 0,
      // height: 230
    }
  };
  const series = [33.33, 33.33, 33.33];

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      {/* <StyledChartWrapper dir="ltr"> */}
      <div>
        <ReactApexChart options={options} series={series} type="donut"  />
      </div>

      {/* <ReactApexChart type="donut" series={chartData} options={chartOptions} height={340} /> */}
      {/* </StyledChartWrapper> */}
    </Card>
  );
}
