import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import CircularProgress from '@mui/material/CircularProgress';

// import { useDispatch } from 'react-redux';

// import { storeCookies } from '../../../utils/storeCookies';

// @mui
import {
	Stack,
	IconButton,
	InputAdornment,
	TextField,
	Link,
	Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// // eslint-disable-next-line import/no-unresolved
// import { makeStyles } from '@material-ui/core/styles';
import Login from '../../../api/login';
// components
import Iconify from '../../../components/iconify';
import { getUserData } from '../../../redux/userSession';

// import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function LoginForm() {
	// const resetPassword = '/forgot-password';
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	// const handleClick = () => {
	// 	navigate('/dashboard', { replace: true });
	// };

	// const useStyles = makeStyles({
	// 	whiteText: {
	// 		color: 'white',
	// 	},
	// });

	// const { user } = useSelector((state) => state.user);

	const [loading, setLoading] = useState(false);
	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email('Wrong email format')
			.min(5, 'Minimum 5 symbols')
			.max(40, 'Maximum 40 symbols')
			.required('Email is required'),
		password: Yup.string()
			.min(5, 'Minimum 5 symbols')
			.max(30, 'Maximum 30 symbols')
			.required('Password is required'),
	});

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const clearFields = () => {
    formik.resetForm(); // Reset form fields
  };

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: LoginSchema,

		// onSubmit: (values) => {
		// 	alert(JSON.stringify(values, null, 2));
		// },

		onSubmit: async (values, { setStatus, setSubmitting }) => {
			enableLoading();
			setTimeout(() => {
				Login(values.email, values.password)
					.then((res) => {
						console.log("Successfully data saved: ", res.data);
						console.log("Successfully data saved: ", res.data.data.user);
						disableLoading();
						if (res.status === 200 && res.data?.data?.user ) {
							enqueueSnackbar('Succesfully login!', {
								variant: 'success',
							});
							console.log('!res.data?.data?.user?.isVerified: ', !res.data?.data?.user?.isVerified)
							if(res.data?.data?.user?.isVerified ){
								dispatch(getUserData(res?.data?.data));
								res?.data?.data?.user?.firstName && localStorage.setItem('firstName', res?.data?.data?.user?.firstName);
								res?.data?.data?.user?.lastName && localStorage.setItem('lastName', res?.data?.data?.user?.lastName);
								res.data?.data?.user?._id && localStorage.setItem('userId' , res.data?.data?.user?._id);
								res.data?.data?.user?.email && localStorage.setItem('email' , res.data?.data?.user?.email);
								localStorage.setItem('access-token' , res.data?.data?.user?.userToken)
								navigate('/dashboard', { replace: true });
								// window.open(`https://iaesirfinance.com`, '_self')
							}
							else{
								navigate('/verification', { replace: true });
							}

							clearFields();
							// dispatch(getUserData(res?.data?.data));
							// storeCookies(res?.data?.data?.token);
						} else {
							enqueueSnackbar('Invalid email or password!', {
								variant: 'error',
							});
						}
					})
					.catch(() => {
						disableLoading();
						setSubmitting(false);
						setStatus(false);
					});
			}, 1500);
		},

    // onSubmit: async()=> {
    //   alert('Submit');
    // }
	});

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing={3}>
					<TextField
						id='email'
						name='email'
						type='text'
						placeholder='Email address'
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
						InputProps={{
							style: { color: 'white' },
							inputProps: {
								style: { color: 'white' },
							},
						}}
					/>

					<TextField
						name='password'
						placeholder='Password'
						value={formik.values.password}
						onChange={formik.handleChange}
						error={
							formik.touched.password && Boolean(formik.errors.password)
						}
						helperText={formik.touched.password && formik.errors.password}
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							style: { color: 'white' },
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										onClick={() => setShowPassword(!showPassword)}
										edge='end'
									>
										<Iconify
											icon={
												showPassword
													? 'eva:eye-fill'
													: 'eva:eye-off-fill'
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
						InputLabelProps={{
							style: { color: 'white' },
						}}
					/>
				</Stack>

				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					sx={{ my: 2 }}
				>
					<Link
						sx={{ cursor: 'pointer' }}
						component={''}
						variant='subtitle2'
						onClick={() => navigate('/forgot-password')}
					>
						Forgot password?
					</Link>
				</Stack>

				<LoadingButton
					fullWidth
					size='large'
					type='submit'
					// disabled={formik.isSubmitting}
					variant='contained'
					// onClick={handleClick}
				>
					{loading ? (
						<span>
							<CircularProgress size='1.5rem' color='inherit' />
						</span>
					) : (
						<Box>Login</Box>
					)}
				</LoadingButton>
			</form>
		</>
	);
}